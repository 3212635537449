import {
    Alert,
    Button,
    Divider,
    Form,
    Modal,
    VIEW_DATE_FORMAT,
    WrappedDateItem,
    dfaValidators,
} from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import moment, { Moment } from 'moment'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { patchRedeemDate } from '@store/dfa/details'

import api from '@services/api'

import { ApprovalRedeemDateModal } from './ApprovalRedeemDateModal'
import style from './style.m.less'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
}
const MIN_REDEEM_WORKDAY = 5 //TODO позже збирать из конфига

export const SetRedeemDateModal: FC<IComponentProps> = ({ isModalVisible, setIsModalVisible }) => {
    const [isApprovalModalVisible, setIsApprovalModalVisible] = useState(false)
    const [redeemDate, setRedeemDate] = useState<Moment>()
    const [minDate, setMinDate] = useState<Moment>()
    const reduxDispatch = useDispatch()
    const [form] = Form.useForm()

    const onCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
        setRedeemDate(undefined)
    }

    const saveRedeemDate = () => {
        if (!redeemDate) {
            return
        }
        const callback = () => {
            onCancel()
        }
        setIsApprovalModalVisible(false)
        reduxDispatch(patchRedeemDate({ date: redeemDate.unix(), callback }))
    }

    const onFinish = () => {
        setIsApprovalModalVisible(true)
    }

    const getMinDate = async () => {
        const { data } = await api.lib.getCalendarWorkday({
            offset: MIN_REDEEM_WORKDAY,
            date: dayjs().toISOString(),
        })
        if (data?.item) {
            setMinDate(moment(data.item))
        }
    }

    useEffect(() => {
        getMinDate()
    }, [])

    const alertText = useMemo(() => {
        return (
            'Планируемая дата погашения должна быть не ранее, чем через 5 рабочих дней от текущей даты.\n' +
            `${
                minDate
                    ? `Ближайшая возможная дата погашения - ${minDate.format(VIEW_DATE_FORMAT)}`
                    : ''
            }`
        )
    }, [minDate])

    return (
        <>
            <Modal
                title="Установление даты погашения выпуска"
                visible={isModalVisible}
                onCancel={onCancel}
                closable
                footer={[
                    <Button borderRadius={12} onClick={onCancel}>
                        Отмена
                    </Button>,
                    <Button
                        type="primary"
                        borderRadius={12}
                        onClick={form.submit}
                        disabled={!redeemDate}
                    >
                        Установить дату
                    </Button>,
                ]}
            >
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <p>
                        Дата погашения устанавливается единожды. Выбранная дата погашения будет
                        сохранена в карточке выпуска ЦФА.
                    </p>
                    <WrappedDateItem
                        className={style.dateInput}
                        placeholder="Выберите дату"
                        onChange={setRedeemDate}
                        required
                        size="large"
                        name="redeem_date"
                        rules={[{ validator: dfaValidators.redeemDate(minDate) }]}
                        showTime={{ defaultValue: moment('20:00', 'HH:mm') }}
                    />
                    <Alert showIcon description={alertText} type="info" />
                    <Divider margin={[16, 0, 0, 0]} />
                </Form>
            </Modal>
            <ApprovalRedeemDateModal
                isModalVisible={isApprovalModalVisible}
                setIsModalVisible={setIsApprovalModalVisible}
                redeemDate={redeemDate}
                saveRedeemDate={saveRedeemDate}
            />
        </>
    )
}
