import { FC, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import moment from 'moment'

import {
    DecisionMakingType,
    DfaStatusEnum,
    ExternalRateIndex,
    ExternalRateIndexTranslate,
    IDfaFront,
    IssueAvailableInvestorsType,
    IssueTypeDfa,
    PriceSource,
    SettlementsType,
    issueTypeDfaTranslate,
    priceSourceTranslate,
    typeOfLawTranslate,
} from '@dltru/dfa-models'
import { SupplyInvestedType } from '@dltru/dfa-models/src/models/dfa/countDfa'
import {
    CardDivider,
    DefaultOptionType,
    EarlyRepayment,
    Form,
    PriceSourceDynamic,
    SettlementCollectComponent,
    SettlementsFormValue,
    Step,
    StepperContext,
    WrappedInput,
    WrappedSelect,
    calcPricePerDfa,
    openMessage,
    useEditable,
} from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { getExternalRateHelper } from '@store/helper'

import { EmissionCardPageContext } from '@pages/DFAEmission/DFAEmissionCardPage/EmissionCardPageContext'

import { CountDFA } from '@components/DFADetails/components/ConditionStep/CountDFA'
import { PriceSourceStatic } from '@components/DFADetails/components/ConditionStep/PriceSourceStatic'
import { TickerInput } from '@components/Form'

import { SetRedeemDateModal } from '../components/SetRedeemDateModal'

const getEarlyButtonShow = (isEmitter: boolean, values: IDfaFront) => {
    if (!values?.redeem_date) {
        return false
    }

    if (isEmitter) {
        return false
    }

    if (values?.status !== DfaStatusEnum.emitment_success) {
        return false
    }

    const now = new Date().valueOf()
    const redeemDate = new Date(values.redeem_date).valueOf()

    if (redeemDate - now < 60 * 60 * 24 * 1000) {
        return false
    }

    return Boolean(values?.is_early_repayment)
}

const optionsTypeOfLaw: DefaultOptionType[] = Object.entries(typeOfLawTranslate).map(
    ([key, value]) => ({
        key,
        value: key,
        label: value,
    }),
)

const optionsPriceSourceType = Object.entries(priceSourceTranslate).map(([key, value]) => ({
    key,
    value: key,
    label: value,
}))

const optionsIssueType = Object.entries(issueTypeDfaTranslate).map(([key, value]) => ({
    key,
    value: key,
    label: value,
}))

const optionsPriceSource = Object.entries(ExternalRateIndexTranslate).map(([key, value]) => ({
    key,
    value: key,
    label: value,
}))

export const IssueConditions: FC<{
    stepIndex: number
    isModification: boolean
    isEmitter: boolean
}> = ({ stepIndex, isModification, isEmitter }) => {
    const navigation = useNavigate()
    const uuid = useSelector(authSelector.selectUserUid)
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)
    const { editable, currentStep, stepsTotal, values } = useContext(StepperContext)
    const { setDisabledActionButtons } = useContext(EmissionCardPageContext)

    const [form] = Form.useForm()

    const [isPriceTypeDynamic, setIsPriceTypeDynamic] = useState(
        values?.price_source_type === PriceSource.dynamic,
    )
    const [currentIssueType, setCurrentIssueType] = useState(values?.issue_type)
    const [checkUniqueTicker, setCheckUniqueTicker] = useState(false)
    const [isRedeemDateModal, setIsRedeemDateModal] = useState(false)
    const [rateIndex, setRateIndex] = useState(ExternalRateIndex.GLDRUB_TOM)

    const [currentSettlementsType, setCurrentSettlementsType] = useState({
        repayment_settlements_type: values?.repayment_settlements_type,
        issue_settlements_type: values?.issue_settlements_type,
    } as SettlementsFormValue)

    const [isManualIssue, setIsManualIssue] = useState(() => {
        if (values?.issue_type === IssueTypeDfa.manual) {
            // form.setFieldsValue({ issue_end_date: undefined })

            form.setFieldsValue({ payment_collect_type: undefined })
            form.setFieldsValue({ repayment_collect_type: undefined })

            setCurrentSettlementsType({
                repayment_settlements_type: SettlementsType.direct,
                issue_settlements_type: SettlementsType.direct,
            })
        }

        return values?.issue_type === IssueTypeDfa.manual
    })

    const [isQualifiedType, setIsQualifiedType] = useState(false)

    const [isDiapasonPrice, setIsDiapasonPrice] = useState(
        form.getFieldValue('supply_invested_type') === SupplyInvestedType.diapason,
    )

    useEffect(() => {
        setIsDiapasonPrice(
            form.getFieldValue('supply_invested_type') === SupplyInvestedType.diapason,
        )
    }, [form.getFieldValue('supply_invested_type')])

    const calcRedeemPrice = () => {
        const priceExternal = Number(form.getFieldValue('price_external'))
        const spread = Number(form.getFieldValue('redemption_spread')) || 0
        const redeemPricePerDfa = (priceExternal / 100) * spread + priceExternal

        form.setFieldsValue({
            redeem_price_per_dfa: redeemPricePerDfa.toFixed(2),
        })
    }

    const onChangeValues = (changedValues: Record<string, unknown>) => {
        if (changedValues && 'ticker_symbol' in changedValues) {
            setCheckUniqueTicker(changedValues.ticker_symbol !== values?.ticker_symbol)
        }
        if (changedValues && 'issue_type' in changedValues) {
            setIsManualIssue(changedValues.issue_type === IssueTypeDfa.manual)
            if (changedValues.issue_type === IssueTypeDfa.manual) {
                // form.setFieldsValue({ issue_end_date: undefined })

                form.setFieldsValue({ payment_collect_type: undefined })
                form.setFieldsValue({ repayment_collect_type: undefined })

                setCurrentSettlementsType({
                    repayment_settlements_type: SettlementsType.direct,
                    issue_settlements_type: SettlementsType.direct,
                })
            }
        }

        if (changedValues && 'issue_available_investors_type' in changedValues) {
            setIsQualifiedType(
                changedValues.issue_available_investors_type ===
                    IssueAvailableInvestorsType.qualified,
            )
        }

        if (changedValues && 'price_source_type' in changedValues) {
            if (changedValues.price_source_type === PriceSource.static) {
                form.setFieldsValue({ redeem_price_per_dfa: '' })
            }
            setIsPriceTypeDynamic(changedValues.price_source_type === PriceSource.dynamic)
        }

        if (changedValues && 'investment_spread' in changedValues) {
            const priceExternal = Number(form.getFieldValue('price_external'))
            const spread = Number(changedValues.investment_spread) || 0
            const pricePerDfa = (priceExternal / 100) * spread + priceExternal

            form.setFieldsValue({
                price_per_dfa: pricePerDfa.toFixed(2),
            })
        }

        if (changedValues && 'redemption_spread' in changedValues) {
            calcRedeemPrice()
        }

        if (changedValues && 'price_source' in changedValues) {
            setRateIndex(changedValues.price_source as ExternalRateIndex)
        }

        if (changedValues && 'supply_invested_type' in changedValues) {
            setIsDiapasonPrice(changedValues.supply_invested_type === SupplyInvestedType.diapason)
        }
    }

    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        isModification &&
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(stepIndex))

    useEffect(() => {
        if (dfa?.redeem_date) {
            form.setFieldsValue({ redeem_date: moment(dfa.redeem_date) })
        }
    }, [dfa?.redeem_date])

    useEffect(() => {
        setDisabledActionButtons(isEdit)
    }, [isEdit])

    const getExternalRate = (index: ExternalRateIndex) => {
        getExternalRateHelper(index)
            .then((result) => {
                form.setFieldsValue({ price_external: (result.item.rate / 100).toFixed(2) })
                calcRedeemPrice()
                calcPricePerDfa(form)
            })
            .catch(() => {
                openMessage({ type: 'error', message: 'Возникли проблемы получении курса ' })
            })
    }

    const isPreliminaryCheckStatus = values?.status === DfaStatusEnum.preliminary_check

    const hideTellerBlock =
        isPreliminaryCheckStatus ||
        (values?.status === DfaStatusEnum.modification &&
            values?.decision_making_type === DecisionMakingType.platform)

    const showSetRedeemDateBtn =
        values?.status === DfaStatusEnum.emitment_success && values?.emitter_id === uuid
    const setRedeemDate = () => {
        setIsRedeemDateModal(true)
    }

    const isTellerApprovalStatus = values?.status === DfaStatusEnum.teller_approval

    const isShowRepaymentButton = getEarlyButtonShow(isEmitter, values)

    return (
        <Step
            stepIndex={stepIndex}
            onChangeValues={onChangeValues}
            form={form}
            stepTitle="Условия выпуска ЦФА"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <WrappedInput
                label="Наименование выпуска"
                className="formComponentItem"
                name="title"
                placeholder="Предложите уникальное название выпуска"
                uneditable={isEdit}
                required
                maxLength={100}
            />
            <TickerInput
                uneditable={isEdit}
                required
                checkUnique={checkUniqueTicker}
                placeholder="Предложите короткое название из 3-6 символов"
            />
            {!isPreliminaryCheckStatus && (
                <>
                    <CardDivider />
                    <WrappedSelect
                        className="formComponentItem"
                        label="Вид прав, удостоверенных ЦФА"
                        name="type_of_law"
                        uneditable={isEdit}
                        required
                        options={optionsTypeOfLaw}
                        disabled
                    />
                </>
            )}
            <WrappedSelect
                className="formComponentItem"
                label="Источник цены"
                name="price_source_type"
                uneditable={isEdit}
                required
                options={optionsPriceSourceType}
            />
            {isPriceTypeDynamic && (
                <div className="formRow">
                    <WrappedSelect
                        className="formComponentItem"
                        label="Московская биржа"
                        name="price_source"
                        uneditable={isEdit}
                        required
                        options={optionsPriceSource}
                        initialValue={ExternalRateIndex.GLDRUB_TOM}
                    />
                </div>
            )}
            <CardDivider />
            <CountDFA
                isPriceTypeDynamic={isPriceTypeDynamic}
                isEdit={isEdit}
                isDiapasonPrice={isDiapasonPrice}
            />
            {!isPriceTypeDynamic && values && (
                <PriceSourceStatic
                    // isQualifiedType={isQualifiedType}
                    isEdit={isEdit}
                    values={values}
                    form={form}
                    isManual={currentIssueType === IssueTypeDfa.manual}
                    setRedeemDate={showSetRedeemDateBtn ? setRedeemDate : undefined}
                />
            )}
            {isPriceTypeDynamic && (
                <PriceSourceDynamic
                    isManual={currentIssueType === IssueTypeDfa.manual}
                    isEdit={isEdit}
                    getExternalRate={getExternalRate}
                    setRedeemDate={showSetRedeemDateBtn ? setRedeemDate : undefined}
                    disabled={{
                        max_supply_invested: isTellerApprovalStatus,
                        min_supply_invested: isTellerApprovalStatus,
                        investment_spread: isTellerApprovalStatus,
                        issue_start_date: isTellerApprovalStatus,
                        issue_end_date: isTellerApprovalStatus,
                        redemption_spread: isTellerApprovalStatus,
                        redeem_date: isTellerApprovalStatus,
                    }}
                    rateIndex={rateIndex}
                />
            )}
            {!hideTellerBlock && (
                <>
                    <WrappedSelect
                        className="formComponentItem"
                        label="Способ осуществления выпуска"
                        name="issue_type"
                        uneditable={isEdit}
                        required
                        options={optionsIssueType}
                        onChange={setCurrentIssueType}
                    />
                    <CardDivider />
                    <SettlementCollectComponent
                        radioName="issue_settlements_type"
                        radioLabel="Способ получения денежных средств в рамках эмиссии"
                        selectName="payment_collect_type"
                        uneditable={isEdit}
                        formValues={currentSettlementsType}
                        isAllowDirect={isManualIssue}
                        disabled={isTellerApprovalStatus}
                    />
                    <CardDivider />
                    <SettlementCollectComponent
                        radioName="repayment_settlements_type"
                        radioLabel="Способ получения денежных средств при погашении выпуска"
                        selectName="repayment_collect_type"
                        uneditable={isEdit}
                        formValues={currentSettlementsType}
                        isAllowDirect={isManualIssue}
                        disabled={isTellerApprovalStatus}
                        onChange={setCurrentSettlementsType}
                    />
                    <CardDivider />
                </>
            )}

            <SetRedeemDateModal
                isModalVisible={isRedeemDateModal}
                setIsModalVisible={setIsRedeemDateModal}
            />
            <EarlyRepayment
                uneditable={isEdit}
                earlyRepayment={Boolean(values?.is_early_repayment)}
                //earlyRepayment={true}
                isDynamicPrice={isPriceTypeDynamic}
                isShowRepaymentButton={isShowRepaymentButton}
                onCreateApplicationClick={() => {
                    navigation('/early-repayment/create', { state: { dfa } })
                }}
            />
        </Step>
    )
}