import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    Form,
    FormSpace,
    StepButtons,
    StepProps,
    WrappedInput,
} from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'

export const IssueRestrictions: FC<StepProps> = ({
    stepIndex,
    currentStep,
    title,
    stepsTotal,
    stepForward,
    stepBack,
    stepsValues,
    isNotCurrentStep,
}) => {
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(stepsValues)
    }, [stepsValues])

    return (
        <Form form={form} size="large" initialValues={stepsValues} onFinish={stepForward}>
            <Card uneditable={isNotCurrentStep}>
                <CardHeader
                    title={title}
                    stepIndex={stepIndex}
                    stepsTotal={stepsTotal}
                    isNotCurrentStep={isNotCurrentStep}
                />
                <CardContent divider={!isNotCurrentStep}>
                    <FormSpace uneditable={isNotCurrentStep}>
                        <WrappedInput
                            className="formComponentItem"
                            label="Ограничение"
                            name="issue_restriction"
                            placeholder="Внесите дополнительную информацию"
                            uneditable={isNotCurrentStep}
                            multiline
                        />
                    </FormSpace>
                </CardContent>
                {!isNotCurrentStep && (
                    <CardFooter>
                        <StepButtons
                            stepIndex={stepIndex}
                            currentStep={currentStep}
                            stepsTotal={stepsTotal}
                            onForward={form.submit}
                            onBack={() => stepBack(form.getFieldsValue(true))}
                        />
                    </CardFooter>
                )}
            </Card>
        </Form>
    )
}
