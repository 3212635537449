import { Box, Button, MiniModal, QuestionCircle, Space, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import { Moment } from 'moment'
import { FC } from 'react'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    redeemDate?: Moment
    saveRedeemDate: () => void
}

export const ApprovalRedeemDateModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    redeemDate,
    saveRedeemDate,
}) => {
    if (!redeemDate) {
        return null
    }
    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <MiniModal
            visible={isModalVisible}
            onCancel={handleCancel}
            width={423}
            title={
                <Space size={0}>
                    <QuestionCircle className="modal-icon modal-question-icon" />
                    Установить дату погашения
                </Space>
            }
            footer={[
                <Button borderRadius={12} onClick={handleCancel}>
                    Отмена
                </Button>,
                <Button borderRadius={12} type="primary" onClick={saveRedeemDate}>
                    Да, установить
                </Button>,
            ]}
        >
            <Box margin={[8, 40, 0, 40]}>
                <p>После сохранения изменить дату погашения будет невозможно.</p>
                <p>Плановая дата погашения - {redeemDate.format(VIEW_DATE_FORMAT)} г.</p>
                <p>Вы действительно хотите установить плановую дату погашения?</p>
            </Box>
        </MiniModal>
    )
}
